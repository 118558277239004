@import url("https://fonts.googleapis.com/css?family=Inconsolata|Merriweather");
.works{
  display: flex;
  /* padding-left: 8vh !important; */
  /* padding-right: 20vh; */
  flex-direction: column;
  font-family: 'Source Code Pro', monospace;
  background: rgb(28, 28, 28);
}
.works h1{
  color: #A56270;
    font-size: 4em;
    padding: 5vh;
}
button {
  cursor: pointer;
}

#portfolio {
  background: rgb(28, 28, 28) !important;
  width: 96%;
  min-height: 100vh;
  background: #ffffff;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(201px, 1fr));
  grid-template-rows: 1fr 1fr;
  grid-gap: 2%;
  padding: 3rem;


}

.project {
  background: rgb(28, 28, 28);
  position: relative;
  background: #F2DAD7;
  overflow: hidden;
}
.project img {
  position: absolute;
  opacity: 0.9;
}
.project p {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 1em 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 3;
}
.project .grid__title {
  position: absolute;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  bottom: 0;
  font-weight: 100;
  font-size: 0.8em;
  z-index: 3;
  text-transform: uppercase;
  color: #474545;
  letter-spacing: 2px;
}

.project:hover .grid__overlay {
  transform: translateY(0%);
}

.project:hover .grid__overlay_git {
  transform: translateY(0%);
}



.grid__overlay {
  background: rgba(229, 182, 177, 0.9);
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: center;
  transform: translateY(101%);
  transition: all 0.3s ease-in-out;
}
.grid__overlay_git {
  background: rgba(229, 182, 177, 0.9);
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: center;
  transform: translateY(101%);
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-content: center;
}
.grid__overlay button {
  background: none;
  outline: none;
  font-weight: 100;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
}
.grid__overlay button:hover {
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  color: #D1A39E;
  transform: scale(1.05);
}
.grid__overlay_git button{
  background: none;
  outline: none;
  margin-top: 2vh;
  font-weight: 100;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
}
.grid__overlay_git button:hover {
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  color: #D1A39E;
  transform: scale(1.05);
}
.overlay {
  position: fixed;
  background: rgba(71, 69, 69, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 3;
}

.overlay.open {
  display: grid;
  align-items: center;
  justify-items: center;
}

.overlay__inner {
  background: #ffffff;
  width: 700px;
  padding: 20px;
  position: relative;
  opacity: 1;
}

.close {
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  outline: 0;
  color: #474545;
  border: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.close:hover {
  color: #D1A39E;
}

.project__image {

 
  margin-left: -50%;
}/*# sourceMappingURL=Work.css.map */
.one{
  width: 24vw;
  margin-left: 8%;
  position: absolute;
  height: 43vh;


  margin-left: 3.9vh;

}
.two{
  width: 36vw;
  margin-left: 8%;
  position: absolute;
  height: 37vh;
  margin-left: -11.1vh;
}
.three{
  width: 33vw;
  margin-left: 8%;
  position: absolute;
  height: 30vh;
  margin-top: 7vh;
  margin-left: -5.1vh;
}
