.mywork{

    display: flex;
    padding-left: 4vh;
    background: rgb(28, 28, 28);
    flex-direction: column;
    font-family: 'Source Code Pro', monospace;
    align-items: flex-start;
    position: relative;

}
.mywork .pra h1{ 
    font-size: 3em;
}
.fake-magicwall ul{
    list-style: none;
    display: flex;

    flex-wrap: wrap;
}
.fake-magicwall li{
    margin-left: 3rem;
}
.fake-magicwall ul li{
    width: 340px;
    height: 202px;
    position: relative;
    overflow: hidden;
    display: list-item;
}
.fake-magicwall .magic-wall_item{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}
.fake-magicwall img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fake-magicwall ul li:hover .magic-wall_item{
    transform: scale(1.14);
    -ms-transform: scale(1.4);
    -webkit-transform: scale(1.14);
}
.fake-magicwall{
    /* background-color: rgba(255, 255, 255, 0.1); */
}
.fake-magicwall ul li a.colorbox{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url(../images/circle.png) no-repeat center; */
    opacity: 0;
    transition: all 150ms ease-out;
    -o-transition: all 150ms ease-out;
    -webkit-transition: all 150ms ease-out;
    transform: scale(0.2);
}
.fake-magicwall ul li:hover a.colorbox{
    transform: scale(0.6);
    opacity: 1;
}
@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  {
    .fake-magicwall li {
        margin-left: 0rem;
    }
    
    .fake-magicwall ul li {
        width: 246px;
        height: 202px;
        margin-top: 2rem;
        position: relative;
        overflow: hidden;
        display: list-item;
    }
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
       
    .fake-magicwall ul li {
  
        margin-top: 2rem;
     
    }
    
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    .fake-magicwall ul li {
  
        margin-top: 2rem;
     
    }
    
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { /* big landscape tablets, laptops, and desktops */ }
