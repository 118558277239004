.space-contact-modal .modal-content {
  background-color: rgba(13, 17, 23, 0.9);
  border: 2px solid #30363d;
  border-radius: 15px;
  color: #c9d1d9;
}

.space-contact-header {
  border-bottom: 1px solid #30363d;
}

.space-contact-header h1 {
  color: #58a6ff;
  font-size: 2.5rem;
  text-shadow: 0 0 10px rgba(88, 166, 255, 0.5);
}

.space-contact-body {
  background: url("https://images.unsplash.com/photo-1506318137071-a8e063b4bec0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80")
    no-repeat center center;
  background-size: cover;
  position: relative;
}

.space-contact-body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(13, 17, 23, 0.8);
}

.space-contact-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.space-contact-info {
  flex: 1;
  min-width: 300px;
  padding-right: 2rem;
}

.space-contact-info h3 {
  color: #58a6ff;
  margin-bottom: 1rem;
}

.space-contact-info ul {
  list-style-type: none;
  padding: 0;
}

.space-contact-info li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.space-contact-info li svg {
  margin-right: 0.5rem;
  color: #58a6ff;
}

.space-contact-form {
  flex: 2;
  min-width: 300px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
  min-width: 200px;
  margin-right: 1rem;
}

.form-group:last-child {
  margin-right: 0;
}

.form-control {
  background-color: rgba(13, 17, 23, 0.8);
  border: 1px solid #30363d;
  color: #c9d1d9;
  border-radius: 5px;
  padding: 0.5rem;
}

.form-control:focus {
  border-color: #58a6ff;
  box-shadow: 0 0 0 0.2rem rgba(88, 166, 255, 0.25);
}
.space-button {
  position: relative;
  padding: 12px 35px;
  background: linear-gradient(to right, #00d2ff, #3a7bd5);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  transition: all 0.3s ease;
}

.space-button::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ff00ee, #00ff00, #00ffff, #ff00ff);
  z-index: -1;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.space-button:hover::before {
  opacity: 1;
}

.space-button-inner {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.space-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #00d2ff, #3a7bd5);
  z-index: -2;
  transition: opacity 0.3s ease-in-out;
}

.space-button:hover::after {
  opacity: 0.8;
}

.space-button:active {
  transform: scale(0.98);
  box-shadow: 0 0 20px rgba(0, 210, 255, 0.5);
}
.btn-primary {
  background-color: #238636;
  border-color: #238636;
  color: #ffffff;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #2ea043;
  border-color: #2ea043;
  box-shadow: 0 0 10px rgba(46, 160, 67, 0.5);
}

.form-submit {
  text-align: right;
}
.space-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-20px);
  animation:
    slideIn 0.3s forwards,
    fadeOut 0.5s 2.5s forwards;
}

.space-message.success {
  background: linear-gradient(45deg, #00ff00, #00ffff);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
}

.space-message.error {
  background: linear-gradient(45deg, #ff0000, #ff00ff);
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
}

.space-message-content {
  display: flex;
  align-items: center;
}

.space-message-icon {
  font-size: 24px;
  margin-right: 10px;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
