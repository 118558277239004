

/* for default - too short - size */
@media all and (max-width: 319px) {
    .myskills{
        height: 120vh;
        font-size: 1.4em;
        padding: 0.2rem;
       
   

         }
         .card {
            width: 19rem!important;
        }

}
@media all and (max-width: 369px) and (min-width: 320px) {
    .myskills{
        height: 190vh;
        font-size: 1.4em;
        padding: 0.2rem;
        flex-direction: column !important;
        display: flex;
   
    flex-direction: column !important;

         }
         .card {
            width: 19rem!important;
        }
        .astronaut {
         

        }

}
@media all and (max-width: 474px) and (min-width: 370px) {
    .myskills{
        padding: 3rem;
      
        font-size: 1.4em;
    
        flex-direction: column !important;
        display: flex;
        padding: 3rem;
    flex-direction: column !important;
         }
         .card {
            width: 22rem!important;
        }
        .astronaut {
         

        }
}
@media all and (max-width: 569px) and (min-width: 475px) {
    .myskills{
        height: 130vh;
        font-size: 1.4em;
        padding: 0.2rem;
       
 
    background: rgb(28, 28, 28);
    flex-direction: column !important;
    font-family: 'Source Code Pro', monospace;
    align-items: center;
    position: relative;
       
         }
         .card {
            width: 28rem!important;
        }
        .astronaut {
         
    
        }
}
@media all and (max-width: 639px) and (min-width: 570px) {
    .myskills{
        height: 120vh;
        font-size: 1.5em;
        padding: 0.2rem;
        flex-direction: column !important;
        display: flex;
   
    flex-direction: column !important;
         }
         .card {
            width: 32rem !important;
        }
        .astronaut {
         

        }
}

@media all and (max-width: 799px) and (min-width: 640px) {
    .myskills{
        height: 100vh;
        font-size: 1.4em;
        padding: 2rem;
         }
         .card {
            width: 30rem !important;
        }
}

@media all and (max-width: 1023px) and (min-width: 800px) {
    .myskills{
        height: 100vh;
        font-size: 2.3vw;
        padding: 2rem;
         }
         .card {
            width: 38rem !important;
        }
}

/* this one goes for default images - bigger sizes */
@media all and (min-width: 1024px) {
    .myskills{
   height: 100vh;
   font-size: 1.6em;
   padding-left: 8vh;
   
    }
    .card{
        width:60rem !important;
    }
}



.myskills{

    display: flex;
    padding-left: 4vh;
    background: rgb(28, 28, 28);
    /* flex-direction: row; */
    font-family: 'Source Code Pro', monospace;
    align-items: center;
    position: relative
}
.astronaut {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

  
height: 14rem;


}
@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

.myskills .pra{
   
    color: white;
    display: inline-block;

}

p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}
.arr{
    margin-top: -13vh;
}