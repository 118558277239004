@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

.aboutme {
  position: relative;
  display: flex;
  padding-left: 4vh !important;
  padding-right: 20vh;
  flex-direction: row;
  font-family: "Source Code Pro", monospace;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

.aboutme::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  opacity: 0.7;
  z-index: -1;
}

.aboutme .pra {
  color: white;
  position: relative;
  z-index: 1;
}

#tag {
  font-size: 0.8em !important;
  opacity: 0.6;
  color: #524e4e;
  font-family: "Handlee", cursive;
}

.section_aboutme {
  display: flex;
  background: rgba(28, 28, 28, 0.8);
  flex-direction: column;
}

.pra h1 {
  color: #ff69b4;
  font-size: 2em;
}

/* Existing cube spinner styles */
.stage-cube-cont {
  z-index: 1;
  position: relative;
  height: 18%;
  top: 0;
  margin-left: 0;
}
.icon-orbit {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.icon-orbit::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}
.icon-galaxy::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  transition: all 0.3s ease;
  animation: orbit 20s linear infinite;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.icon:hover {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.8);
}

.icon:nth-child(1) {
  animation-delay: -0s;
}
.icon:nth-child(2) {
  animation-delay: -3.33s;
}
.icon:nth-child(3) {
  animation-delay: -6.66s;
}
.icon:nth-child(4) {
  animation-delay: -10s;
}
.icon:nth-child(5) {
  animation-delay: -13.33s;
}
.icon:nth-child(6) {
  animation-delay: -16.66s;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(120px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(120px) rotate(-360deg);
  }
}

.star {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: twinkle 2s infinite;
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
}

.cubespinner div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0px lightyellow;
}

/* Existing face styles and keyframes */
.face1 {
  transform: translateZ(100px);
  color: #dd0031;
}
.face2 {
  transform: rotateY(90deg) translateZ(100px);
  color: #f06529;
}
.face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  color: #28a4d9;
}
.face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  color: #5ed4f4;
}
.face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  color: #efd81d;
}
.face6 {
  transform: rotateX(-90deg) translateZ(100px);
  color: #ec4d28;
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

/* Responsive styles */
@media all and (max-width: 319px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media all and (max-width: 639px) and (min-width: 320px) {
  .aboutme {
    flex-direction: column !important;
    height: 133vh;
    padding-right: 1px !important;
  }
  .aboutme .pra {
    font-size: 1.4em;
    margin-top: 6vh;
  }
  .stage-cube-cont {
    position: absolute;
    top: 0;
    margin-left: 0;
  }
}

@media all and (max-width: 639px) and (min-width: 320px) and (max-height: 614px) {
  .aboutme {
    height: 150vh !important;
  }
}

@media all and (max-width: 639px) and (min-width: 320px) and (max-height: 715px) and (min-height: 615px) {
  .aboutme {
    height: 134vh !important;
  }
}

@media all and (max-width: 799px) and (min-width: 640px) {
  .aboutme {
    flex-direction: column !important;
    height: 125vh;
  }
  .aboutme .pra {
    font-size: 1.4em;
    padding: 4vh;
  }
  .stage-cube-cont {
    width: 20;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media all and (max-width: 1023px) and (min-width: 800px) {
  .aboutme {
    flex-direction: column !important;
    height: 100vh;
  }
  .aboutme .pra {
    font-size: 1.4em;
    padding: 4vh;
  }
  .stage-cube-cont {
    width: 52%;
    height: 18%;
  }
}

@media all and (min-width: 1024px) {
  .aboutme {
    height: 86vh;
  }
  .aboutme .pra {
    padding-right: 28vh !important;
    font-size: 1.6em;
    padding: 0px 0vh 0 0vh;
  }
}
