
@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .func .work {
    width: 45% !important;
    padding: 0px !important;
    font-size: 0.8em !important; 
  }
.experience{
  padding: 1vh;
}
  .imgpro{
    border-radius: 50%;
    height: 20vh;
  }
  .resume{

    display: flex;   
     flex-direction: column !important;
    align-items: center  !important;
  }
  .resume .how_am_i {
    background-color: #912E54;
    box-sizing: border-box;
    float: left;
    height: 100%;
    width: 100%;
}
.profile{
  padding: 3vh;

}
.a{
  display: none !important;
}
.profile::after {
  height: 45px !important;
  bottom: -22px !important;
}

.about_me h3 {
  margin-top: 5vh !important;
  color: white !important;
}
.about_me >p{
  padding: 4vh;
}
.all_skills{
  width: 90vw;
}


.about_me_down{
  color: white;
width: 100%;
padding: 3vw;

 }
 .about_me_down .icons{
  width: 100%;
  color: white;
  display: inline-flex !important;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
 
 }
 .profile::after {

  transform: skewY(2deg) !important;

}
  
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) {
  .func .work {
    width: 45% !important;
    padding: 0px !important;
    font-size: 0.8em !important; 
  }
.experience{
  padding: 1vh;
}
  .imgpro{
    border-radius: 50%;
    height: 20vh;
  }
  .resume{

    display: flex;   
     flex-direction: column !important;
    align-items: center  !important;
  }
  .resume .how_am_i {
    background-color: #912E54;
    box-sizing: border-box;
    float: left;
    height: 100%;
    width: 100%;
}
.profile{
  padding: 3vh;

}
.a{
  display: none !important;
}
.profile::after {
  height: 45px !important;
  bottom: -22px !important;
}

.about_me h3 {
  margin-top: 5vh !important;
  color: white !important;
}
.about_me >p{
  padding: 4vh;
}
.all_skills{
  width: 76vw;
}


.about_me_down{
  color: white;
width: 100%;
padding: 3vw;

 }
 .about_me_down .icons{
  width: 100%;
  color: white;
  display: inline-flex !important;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
 
 }
 .profile::after {

  transform: skewY(2deg) !important;

}
.col-sm-5 {
  flex: 0 0 auto;
  width: 9.666667% !important;
}
  
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
  .func .work {
    width: 46%;
;
  
    height: 43vh !important;
}
  .modal {

    --bs-modal-width: 780px !important;
  }
  .about_me_down{
    display: none;
  }
  .all_skills {

  /* margin-right: 74px; */

  width: 93%;
  margin-left: -1vh;


}
  .profile{
  
    padding: 4vh;

 
    height: 39vh;

  }
  .imgpro{
    border-radius: 50%;
    height: 20vh;
  }

  .about {
    padding-left: 2.1vh;
    font-size: 0.84em;
}
.resume .how_am_i{

  width: 29vh;
  height: 100vh !important;


}
.profile h1{


  font-size: 1.5em;

}
.profile h3{
  font-size: 1em;

}
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {
  .about_me_down{
    display: none;
  }
  .a{
    padding-top: 2vh;
    padding-left: 4vh;

  }
  .all_skills {

  /* margin-right: 74px; */

  width: 93%;
  margin-left: -1vh;


}
  .profile{
  
    padding: 4vh;

 
    height: 39vh;

  }
  .imgpro{
    border-radius: 50%;
    height: 20vh;
  }

  .about {
    
    padding-left: 2.1vh;
    font-size: 0.83em;
}
.resume .how_am_i{
  height: 100vh !important;
  width: 29vh;


}
.profile h1{


  font-size: 1.5em;

}
.profile h3{
  font-size: 1em;

}

  /* big landscape tablets, laptops, and desktops */ }


.resume{
  background: #201F41;


  
}

.resume .how_am_i{
    background-color: #912E54;
    box-sizing: border-box;
    float: left;

    height: 100%;

}
.profile{

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DD7C86;
    position: relative;
    width: 100%;
    z-index: 2;

}
.profile h1{
    color: white;



}
.profile h3{
    color: white;

}

.profile::after {
    content: "";
    position: absolute;
    background: #422D53;
    width: 100%;
    height: 33px;
    bottom: -14px;
    left: 0;
    transform: skewY(-5deg);
    z-index: -1;
}
 .about_me{
  color: white;
    display: flex;
        flex-direction: column;
}
.icons{
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}
.contact{




    display: flex;
    align-items: flex-start;
    display: inherit;
    /* justify-content: center; */
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: column;
    padding: 0;
}

.contact>li{
    margin-top: 0.3rem;
    list-style-type: none;

} 

 .about_me h3{
  margin-top: 2vh;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

 }
 .space{
  margin-left: 2vh;
 }
 .func{
  display: flex;
  color: white;


  padding-top: 2rem;
  justify-content: center;
  align-content: flex-start;
  flex-direction: row;
  align-items: stretch;


}
.education{
  margin-left:2px;
}
.a{
 margin-left: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
}
.func .work {
  width: 46%;
  background: #422D53;
  padding: 15px;

}

.func .work
{
float: left;
}
.work small
{
display: block;
opacity: 0.7;
}

.experience{
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.experience ul {
  color: white;
  list-style-type: none;
  padding: 0;
}
.experience_list  li{
  

}
#skill_title{
color: white;
}
.experience_list ul li {
  position: relative;
  margin-left: 15px;
  padding-left: 25px;
  list-style-type: none;
  padding-bottom: 15px;
}
.work ul li:hover::before
{
animation: circle 1.2s infinite;
}
.work ul li:hover span
{
color: yellow
}
@keyframes circle {
  from {
    box-shadow: 0 0 0 0px yellow;
  }
  to {
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0);
  }
}
.work ul li:first-of-type::before
{
width: 10px;
height: 10px;
left: 1px;
}
.work ul li:last-of-type
{
padding-bottom: 3px;
}
.work ul li:last-of-type::after
{
border-radius: 1.5px;
}
.work ul li::before,
.work ul li::after
{
content: "";
display: block;
position: absolute;
}
.resume .func .work ul li::before,
.resume .func .edu ul li::before {
width: 7px;
height: 7px;
border: 3px solid white;
background: orange;
border-radius: 50%;
left: 3px;
z-index: 1;
}

.resume .func .work ul li::after
{
width: 3px;
height: 100%;
background: white;
left: 5px;
top: 0;
}
.skills_place {
  margin-top: 1.2rem;

  display: flex;
  /* margin-left: 3rem; */
  align-items: center;
  flex-direction: column;
}
.skills {
  display: flex;
  /* margin: 0 auto; */
  width: 91%;
  flex-direction: column;
  align-content: stretch;
  flex-wrap: nowrap;
  align-items: stretch;
}
.all_skills {
  
  /* margin-right: 74px; */
  display: flex;

  flex-direction: column;
  align-items: center;
  background: #422D53;
  align-content: flex-start;

}
.skills_header{
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
padding-left: 1rem;
padding-top: 1rem;



}
.skills>ul li{

  list-style-type: none;

} 
.progress {
  --bs-progress-height: 0.5rem;
}
.modal-content {
  align-items: center !important;
}
