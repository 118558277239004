/* Imports */
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");

/* Base Styles */
.home {
  display: flex;
  justify-content: center; /* שינוי ל-center במקום flex-start */
  align-items: center;
  background: url("./d.jpg") repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-left: 5vw; /* Add left padding */
}
@keyframes twinkle {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.star {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}
.shooting-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  box-shadow:
    0 0 0 4px rgba(255, 255, 255, 0.1),
    0 0 0 8px rgba(255, 255, 255, 0.1);
  animation: shootingStar 3s linear infinite;
}
@keyframes shootingStar {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(-500px) translateY(500px);
    opacity: 0;
  }
}
.star:nth-child(3n) {
  width: 1px;
  height: 1px;
  animation: twinkle 3s infinite;
}

.star:nth-child(3n + 1) {
  width: 2px;
  height: 2px;
  animation: twinkle 4s infinite 1s;
}

.star:nth-child(3n + 2) {
  width: 3px;
  height: 3px;
  animation: twinkle 5s infinite 0.5s;
}

/* הוספת אפקט ערפול קל לרקע */
.home::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(
      1px 1px at 20px 30px,
      #fff,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(1px 1px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 50px 160px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 130px 80px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 160px 120px, #fff, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation: twinkle 5s infinite;
  z-index: -1;
}
.home::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: -1;
}

/* Typography */
.first_header {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 120px;
  z-index: 2;
  position: relative;
  max-width: 60vw;
}

/* Letters Animation */
.letters-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.letter {
  font-family: "Exo", sans-serif;
  font-weight: 900;
  color: white;
  font-size: clamp(24px, 4vw, 48px); /* שימוש ב-clamp לגודל גופן רספונסיבי */
  opacity: 0;
  transition: all 1s ease;
  margin-right: 0.1em;
}

.letter-active {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.profession .letter {
  font-size: clamp(16px, 2vw, 24px) !important;
}

#space {
  width: 0.5em;
  display: inline-block;
}
.shapes {
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: 100%;
  overflow: hidden;
}

.shape0 {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.shape1 {
  width: 40px;
  height: 40px;
}
.shape2 {
  width: 60px;
  height: 60px;
}
.shape3 {
  width: 30px;
  height: 30px;
}
.shape4 {
  width: 50px;
  height: 50px;
}
.shape5 {
  width: 70px;
  height: 70px;
}

.planets,
.human {
  position: absolute;
  transition: transform 0.3s ease-in-out;
}

.planets:hover,
.human:hover {
  transform: scale(1.1);
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.planet1 {
  animation: float 8s infinite ease-in-out;
}
.planet8 {
  animation: float 12s infinite ease-in-out reverse;
}
.planet9 {
  animation: float 10s infinite ease-in-out;
}
.planet10 {
  animation: float 9s infinite ease-in-out reverse;
}
.planet11 {
  animation: float 11s infinite ease-in-out;
}
.human {
  animation: float 7s infinite ease-in-out;
}
/* Login Box Styles */
.login-box {
  align-self: flex-start;
  margin-top: 2vh;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
}

.login-box a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
  background: transparent;
  border: 2px solid #03e9f4;
  border-radius: 5px;
}

.login-box a:hover {
  background: #03e9f4;
  color: #fff;
  box-shadow:
    0 0 5px #03e9f4,
    0 0 25px #03e9f4,
    0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

/* Login Box Animations */
@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

/* Miscellaneous */
#home_tag {
  font-size: 1em !important;
  opacity: 0.6;
  color: white;
  font-family: "Handlee", cursive;
  margin-bottom: 0.5em;
}

/* Media Queries */
@media (max-width: 768px) {
  .home {
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .first_header {
    left: 0;
    max-width: 100%;
    padding: 20px;
  }

  .letter {
    font-size: clamp(18px, 5vw, 24px);
  }

  .profession .letter {
    font-size: clamp(14px, 3vw, 18px) !important;
  }

  #home_tag {
    font-size: 0.8em !important;
  }

  .login-box {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .login-box a {
    font-size: 14px;
    padding: 10px 20px;
    letter-spacing: 2px;
  }
}

@media (min-width: 1200px) {
  .letter {
    font-size: 48px; /* Cap the font size for very large screens */
  }

  .profession .letter {
    font-size: 24px !important;
  }
}

/* Ensure consistent styling across all screen sizes */
@media all and (min-width: 320px) {
  .login-box a,
  .login-box a:hover,
  .login-box a:focus,
  .login-box a:active {
    background: transparent;
    color: #03e9f4;
    border: 2px solid #03e9f4;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .login-box a:hover {
    background: #03e9f4;
    color: #fff;
    box-shadow:
      0 0 5px #03e9f4,
      0 0 25px #03e9f4,
      0 0 50px #03e9f4,
      0 0 100px #03e9f4;
  }
}
