
html{
  height:100% !important;
  width:100%!important;
   margin:0px; 
   padding:0px;
   scroll-behavior: smooth;
 

   


}
body{

  width:100% !important; 
  margin:0px;
   padding:0px;

   scroll-behavior: smooth;

}
/* Track */
  /* Designing for scroll-bar */
  ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.85);

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: black;
 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}